import React, { useState } from 'react'
import { Center, useDisclosure } from '@chakra-ui/react'
import { StaticImage } from 'gatsby-plugin-image'
import Layout from '../Layout'

import Box from '../../components/Box'
import Text from '../../components/Text'
import Flex from '../../components/Flex'
import Button from '../../components/Button'
import Container from '../../components/Container'
// import RegisterModal from '../../components/RegisterModal'
import ContactModal from '../../components/ContactModal'
import BackgroundImage from '../../components/BackgroundImage'
import { Media, responsive } from '../../components/ThemeProvider/theme'

import observe from './observe.svg'
import opendoor from './opendoor.svg'
import cover from './cover.svg'

const buttons = [
  {
    text: '立即測測看',
    href: 'https://exam.redesigninfo.cc/',
    bg: 'custom.Yellow',
    fontSize: responsive('1.125em', '1.375em')
  },
  {
    text: '我測過了，想登記搶先收到下一個測驗',
    fontSize: responsive('0.875rem', '1.25em'),
    to: '/subscribe'
    // openModal: true
  },
  {
    text: '我測過了，想看四萬人的認知測驗報告',
    fontSize: responsive('0.875rem', '1.25em'),
    to: '/萬人分析報告/',
  },
]

const gains = [
  {
    text: '專屬於你的視覺化認知分析報告',
    img: <StaticImage placeholder="none" layout="fullWidth" src="chart.png" alt="圖表icon" />
  },
  {
    text: '根據結果為你挑選的個人化學習內容',
    img: <StaticImage placeholder="none" layout="fullWidth" src="paper.png" alt="文章icon" />
  },
  {
    text: '完整的萬人認知分析報告',
    img: <StaticImage placeholder="none" layout="fullWidth" src="map.png" alt="地圖icon" />
  },
]

const ovals = [
  {
    width: '182px',
    left: '-63px',
    top: responsive('1.875em', '2em')
  },
  {
    width: '5em',
    left: responsive('229px', '317px'),
    top: responsive('-0.5em', '0.5em')
  },
  {
    width: '182px',
    left: responsive('226px', '297px'),
    top: responsive('5.625em', '158px')
  },
]

const DesBlock = ({ title, content, ...props}) => (
  <Box.Relative
    mx="auto"
    maxWidth="30em"
    px={responsive('2.5em', '2em')}
    {...props}
  >
    {ovals.map((p, i) => (
      <Box.Absolute {...p} key={i}>
        <StaticImage placeholder="none" layout="fullWidth" src="oval.png" />
      </Box.Absolute>
    ))}
    <Box.Relative
      whiteSpace="pre-wrap"
      fontSize={responsive('1em', '1.25em')}
      lineHeight={responsive('1.5', '1.6')}
      fontWeight="bold">
      {title}
    </Box.Relative>
    <Box.Relative
      whiteSpace="pre-wrap"
      fontSize={responsive("0.875em", '1.125em')}
      lineHeight={responsive('1.7', '1.78')}
      mt={responsive('1.125rem', '1.5rem')}
    >
      {content}
    </Box.Relative>
  </Box.Relative>
)

const Exam = () => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [openContact, handleOpen] = useState()
  return (
    <Layout overflow="hidden" title="台灣認知大考驗">
      <Container maxWidth="30em">
        <Box pt="1.375em">
          <BackgroundImage src={cover} ratio={269 / 258}  />
        </Box>
        <Box.Relative pt={responsive('0.75em', '2em')}>
          <Text.Bold fontSize={responsive('1em', '1.5em')} lineHeight="1">來！測測看你與台灣的距離有多遠？</Text.Bold>
          <Text.S mt="0.75rem">
            因為太喜歡問「欸！你知道嗎？」<br />
            於是我們做了100題的台灣認知大考驗<br />
            想知道大家有哪些不知道<br />
            和大家知道的台灣長什麼樣<br />
            <br />
            花10分鐘，<br />
            你也可以測測看自己與台灣的距離有多遠<br />
          </Text.S>
          <Box.Absolute
            width={responsive('7em', '15.5em')}
            bottom={responsive('3.125rem', '-3em')}
            right={responsive('-3.325em', '-10.4375em')}
          >
            <BackgroundImage src={observe} ratio={113 / 97} />
          </Box.Absolute>
        </Box.Relative>
        <Box.Relative mt={responsive('1.875em', '3em')}>
          <Media at="mobile">
            <Box.Absolute width="4.625em" bottom="-0.5em" left="-2.25em">
              <BackgroundImage src={opendoor} ratio={82 / 132} />
            </Box.Absolute>
          </Media>
          {buttons.map(({ text, href, openModal, to, ...props }, k) => (
            <Box mt={k && responsive('0.625em', '1em')} textAlign="center" key={k}>
              <Button
                // onClick={() => {
                //   if(openModal) {
                //     onOpen()
                //   }
                // }}
                to={to}
                href={href}
                _hover={{ ...props.bg }}
                {...props}
              >
                {text}
              </Button>
            </Box>
          ))}
        </Box.Relative>
      </Container>
      {/* <RegisterModal isOpen={isOpen} onClose={onClose} /> */}
      <DesBlock
        mt={responsive('3.125em', '6em')}
        pb={responsive('1.75em', '6.5em')}
        title={'圖像化你對台灣的認知\n認識你有所不知的台灣'}
        content={'「發現自己的不知道，就是知道的開始」\n用直覺回答100個關於臺灣的問題，完成測驗就能獲得專屬的圖像化認知分析報告！\n解析你對台灣的認知與實際狀況有多少落差'}
      />
      <Box mx="auto" maxWidth="480px">
        <StaticImage layout="fullWidth" src="report.png" />
        <StaticImage layout="fullWidth" src="analysis.png" />
      </Box>
      <DesBlock
        mt={responsive('4em', '5.5em')}
        pb={responsive('3.25em', '6.25em')}
        title={'用好玩且個人化的方式\n拉近你與台灣的距離'}
        content={'「人在意識到認知落差後更願意學習新知」\n我們找來5個夥伴，為你進行個人化的學習推薦：用選書、片單、假新聞等等不同有趣的內容，帶你拉近與台灣之間的距離！'}
      />
      <Box maxWidth="480px" px="0.625em" mx="auto">
        <StaticImage layout="fullWidth" src="./donate.png" />
      </Box>
      <Center bg="custom.Yellow" mt={responsive('2.5em', '3.625em')}>
        <Box p={responsive("2.5em", '3.5em')}>
          <Text.Bold fontSize={responsive('1em', '1.5em')}>
            只要10分鐘<br />
            和6萬人一起測測看自己與台灣的距離
          </Text.Bold>
          <Text
            mt={responsive('1.25rem', '1rem')}
            fontSize={responsive('0.875em', '1.25em')}
            fontWeight="bold"
          >
            花10分鐘完成測驗，你可以獲得:
          </Text>
          <Center>
            <Box mt="1.75em">
              {gains.map(({ text, img }, i) => (
                <Flex mt={i && '0.75em'} alignItems="center" key={i}>
                  <Box width={responsive('2em', '2.5em')} mr="0.5em">
                    {img}
                  </Box>
                  <Text.S fontWeight="bold">{text}</Text.S>
                </Flex>
              ))}
            </Box>
          </Center>
        </Box>
      </Center>
      <Flex flexDirection="column" alignItems="center" pt={responsive('2.5em', '4em')} pb={responsive('5.5em', '6.75em')}>
        <Button.Yellow
          width={responsive('300px', '416px')}
          fontSize={responsive('1rem', '1.375rem')}
          py="1.125rem"
          href="https://exam.redesigninfo.cc/"
        >前往測測我與台灣的距離</Button.Yellow>
        <Button
          width={responsive('300px', '416px')}
          mt={responsive('0.625rem', '1rem')}
          fontSize={responsive('1rem', '1.375rem')}
          onClick={() => handleOpen(true)}
        >教育或各種合作歡迎與我們聊聊</Button>
      </Flex>
      <ContactModal isOpen={openContact} onClose={() => handleOpen(false)} />
    </Layout>
  )
}

export default Exam
