import React, { useMemo, useState } from 'react'
import { Form, Formik } from 'formik'
import * as yup from 'yup'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalCloseButton,
} from '@chakra-ui/modal'
import { useDisclosure } from '@chakra-ui/hooks'

import Text from './Text'
import Button from './Button'
import { responsive } from './ThemeProvider/theme'

import { renderField } from '../templates/checkout'
import useFirebaseFunctions from '../utils/useFirebaseFunctions'

const contactFields = [
  { name: 'name', label: '姓名' },
  { name: 'email', label: 'E-mail' },
  { name: 'phone', label: '電話' },
  {
    name: 'type',
    label: '詢問類型',
    type: 'select',
    options: [
      '找我們演講 / 工作坊',
      '有大量採購的需求',
      '有內容授權的需求',
      '其他事項詢問',
    ],
  },
  {
    name: 'ask',
    label: '詢問事項',
    type: 'textarea',
  }
]

const ContactModal = ({ isOpen, onClose }) => {
  const handleContact = useFirebaseFunctions('handleContact')
  const [submitted, setSubmitted] = useState()

  const schema = useMemo(() => yup.object({
    name: yup.string().required('此欄位必填'),
    email: yup.string().email().required('此欄位必填'),
    phone: yup.string().required('此欄位必填'),
    type: yup.string().required('此欄位必填'),
    ask: yup.string().required('此欄位必填'),
  }), [])
  return (
    <Modal size="2xl" preserveScrollBarGap isCentered isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent width={responsive("90%", '100%')}>
        <ModalCloseButton />
        <ModalBody textAlign="center">
          <Formik
            initialValues={{}}
            onSubmit={async (values, formikBag) => {
              try {
                await handleContact(values)
                setSubmitted(true)
              } catch (e) {
                console.error(e)
              }
              formikBag.setSubmitting(false)
            }}
            validationSchema={schema}
          >
            {({ isSubmitting }) => (
              submitted ? (
                <Text.P my="2em">已收到你的訊息，我們會盡速回覆!</Text.P>
              ) : (
                <Form>
                  {contactFields.map(renderField)}
                  <Button isLoading={isSubmitting} my="1em" type="submit">確認送出</Button>
                </Form>
              )
            )}
          </Formik>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

const ModalButton = ({ title, text, ...props }) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  return (
    <>
      <button onClick={onOpen} {...props} />
      <ContactModal
        title={title}
        text={text}
        isOpen={isOpen}
        onClose={onClose}
      />
    </>
  )
}

ContactModal.Button = ModalButton

export default ContactModal
